.userCard {
}

.userCardLink {
  color: inherit !important;
}

.userCardLink:hover {
  text-decoration: none !important;
}

.selectableUserCard {
  padding: 10px;
  cursor: pointer;
}

.selectableUserCard:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
