.avatar {
  vertical-align: middle;
}

.avatarInline > img {
  display: inline-block !important;
  vertical-align: bottom !important;
}

.profile {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
