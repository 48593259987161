.empBody {
  max-height: 800px;
  min-height: 800px;
  height: 800px;
  overflow-y: auto;
}

.empList {
  min-height: 350px;
  max-height: 350px;
  overflow-y: auto;
  margin-top: 10px;
}
